<template>
  <div class="flex flex-col items-center justify-center h-screen">
    <div v-if="form.verifying">
      <div class="text-xl font-medium text-gray-500">
        {{ t('verifying_item', { action: t('email') }) }}
      </div>
      <EStoreLoadingIndicator :loading="form.verifying" />
    </div>

    <div v-else-if="form.sending">
      <div class="text-xl font-medium text-gray-500">
        {{ t('link_resending', { action: t('the_verify') }) }}
      </div>
      <EStoreLoadingIndicator :loading="form.sending" />
    </div>

    <div v-else-if="form.failed">
      <!-- Submit -->
      <EStoreResponseError class="my-2" api-key="verify_email" />
      <EStoreSubmitButton
        :title="t('resend_item', { name: t('verify_link') })"
        class="my-5 text-blue-500 underline"
        @submit="resendHandler"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
definePageMeta({ middleware: 'auth' })

const { form, resendHandler, verifyHandler } = useVerifyEmail()

const { url } = useDomainHost()

const { store } = useDomainState()

const { t } = useI18n()

// TODO: https://www.npmjs.com/package/vue3-toastify
// resendSuccess: "تم ارسال رابط التفعيل إلى بريدك الالكتروني",
// resendFailed: "فشل إرسال رابط التفعيل",
// verifySuccess: "تم تفعيل الحساب",
// verifyFailed: "فشل تفعيل الحساب",

onMounted(() => {
  verifyHandler()
})

useServerSeoMeta(
  useOgMeta(
    url + '/auth/verify-email',
    t('verify_email'),
    store.value?.about,
    store.value?.thumb
  )
)

useHead({
  title: t('verify_email'),
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/auth/verify-email')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('verify_email'), url + '/auth/verify-email')
    ])
  ]
})
</script>
